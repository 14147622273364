<template>
  <v-data-table
    must-sort
    fixed-header
    show-expand
    :loading="loading"
    :headers="headers"
    :items="newsletters"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 50, 100, 200 ],
      itemsPerPageText: `Newsletters ${ $t('per page') }:`
    }"
    no-data-text="No newsletters found"
    class="elevation-2"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col
          v-if="$auth.check({ newsletters: ['edit'] })"
          class="shrink"
        >
          <add-newsletter
            icon="fal fa-newspaper"
            button
          />
        </v-col>
      </v-row>
    </template>
    <template #item.date="{ item }">
      {{ (item.date) ? $moment(item.date).format("M/D/Y") : "" }}
    </template>

    <template
      v-if="$auth.check({ newsletters: ['edit'] })"
      #item.action="{ item }"
    >
      <v-menu
        bottom
        right
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            small
            ripple
            icon
            plain
            v-on="on"
          >
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list
          class="py-0"
          dense
        >
          <v-list-item>
            <add-newsletter
              title="Edit Newsletter"
              icon="fal fa-newspaper"
              :data="item"
            />
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template #expanded-item="{ item }">
      <td :colspan="headers.length+1">
        <v-row class="my-5">
          <v-col cols="12">
            <span v-html="item.content" />
          </v-col>
        </v-row>
      </td>
    </template>

    <template #page-text="props">
      Showing {{ props.pageStart }} - {{ props.pageStop }} of {{ props.itemsLength }} references
    </template>
  </v-data-table>
</template>
<script>
  import Vue2Filters from 'vue2-filters'

  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      AddNewsletter: () => import('@/components/tables/dialogs/AddNewsletter.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        loading: false,
        options: {
          search: null,
          filter: this.$route.query,
          sortBy: ['created_at'],
          sortDesc: [false],
        },
        totalItems: null,
        headers: [
          {
            text: 'Date',
            value: 'date',
          },
          {
            sortable: false,
            align: 'right',
            value: 'action',
          },
        ],
        newsletters: [],
      }
    },
    watch: {

      options: {
        deep: true,
        handler () {
          this.fetchNewsletters()
        },
      },
    },
    mounted() {
      this.$root.$on('fetch-newsletter', () => {
        this.fetchNewsletters()
      })
    },
    methods: {
      fetchNewsletters () {
        this.loading = true
        const promise = this.axios.get('admin/newsletters', {
          params:
            {
              ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'asc' : 'desc',
              search: this.options.search,
            },
              ...this.$route.query,
            },
        })

        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchNewsletters()
            return
          }
          this.totalItems = response.data.total
          this.options.page = response.data.current_page
          this.options.itemsPerPage = Number(response.data.per_page)
          this.newsletters = response.data.data
        }).catch(error => {
          this.$toast.error(error)
        }).finally(() => {
          this.loading = false
          this.open = false
        })
      },
    },
  }
</script>
